import { Injectable } from '@angular/core';
import {
  CharacteristicUUID,
  commandSequenceInitiate,
  commandSequenceInventory,
  ServiceUUID,
} from 'src/app/utils/commands';
import { delay, hexStringToByte } from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class BlueToothService {
  deviceOptions: RequestDeviceOptions = {
    filters: [{
      namePrefix : 'CS108Reader'
    }],
    optionalServices: [ServiceUUID.communication], // Required to access service later.
  };

  private currentCommand: string = '';
  private _hasCommandsExecuted: boolean = false;
  private _isDeviceActive: boolean = false;

  private _device: BluetoothDevice | undefined;
  private _server: BluetoothRemoteGATTServer | undefined;
  private _communicationService: BluetoothRemoteGATTService | undefined;
  private _notifyCharacteristic: BluetoothRemoteGATTCharacteristic | undefined;
  private _writeCharacteristic: BluetoothRemoteGATTCharacteristic | undefined;

  get device(): BluetoothDevice | undefined {
    return this._device;
  }

  set device(value: BluetoothDevice | undefined) {
    this._device = value;
  }

  get server(): BluetoothRemoteGATTServer | undefined {
    return this._server;
  }

  set server(value: BluetoothRemoteGATTServer | undefined) {
    this._server = value;
  }

  get communicationService(): BluetoothRemoteGATTService | undefined {
    return this._communicationService;
  }

  set communicationService(value: BluetoothRemoteGATTService | undefined) {
    this._communicationService = value;
  }

  get notifyCharacteristic(): BluetoothRemoteGATTCharacteristic | undefined {
    return this._notifyCharacteristic;
  }

  set notifyCharacteristic(
    value: BluetoothRemoteGATTCharacteristic | undefined) {
    this._notifyCharacteristic = value;
  }

  get writeCharacteristic(): BluetoothRemoteGATTCharacteristic | undefined {
    return this._writeCharacteristic;
  }

  set writeCharacteristic(
    value: BluetoothRemoteGATTCharacteristic | undefined) {
    this._writeCharacteristic = value;
  }

  get hasCommandsExecuted(): boolean {
    return this._hasCommandsExecuted;
  }

  set hasCommandsExecuted(value: boolean) {
    this._hasCommandsExecuted = value;
  }

  get isDeviceActive(): boolean {
      return this._isDeviceActive ? true : false;
  }

  set isDeviceActive(value: boolean) {
      this._isDeviceActive = value;
  }

  async getDevice(): Promise<BluetoothDevice> {
    try {
      if (!this.device) {
        this.device = await navigator.bluetooth.requestDevice(this.deviceOptions);
        this._isDeviceActive = true;
      }
      return this.device;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async setupAndConnectToGATTServer(): Promise<void> {
    try {
      console.log('Connecting to GATT server...');
      this.server = await this.device?.gatt?.connect()!;
    } catch (error) {
      console.log(error);
      this.disconnectDeviceAndServices();
      throw error;
    }
  }

  async getCommunicationService(retries: number, retry = 0): Promise<void> {
    try {
      console.log('Getting communication service..');
      if (!this.device?.gatt?.connected) {
        await this.setupAndConnectToGATTServer();
      }
      this.communicationService = await this.server?.getPrimaryService(ServiceUUID.communication);
    } catch (error) {
      if (retry < retries) {
        console.log(`GetCommunicationService failed, doing a retry. Error= ${error}`);
        await delay(500);
        await this.getCommunicationService(retries, retry + 1);
      } else {
        this.disconnectDeviceAndServices();
        throw error;
      }
    }
  }

  async getNotificationCharacteristic(): Promise<BluetoothRemoteGATTCharacteristic> {
    try {
      console.log('Getting notification characteristics...');
      this.notifyCharacteristic =await this.communicationService?.getCharacteristic(CharacteristicUUID.notification);
      return this.notifyCharacteristic!;
    } catch (error) {
      console.log(error);
      this.disconnectDeviceAndServices();
      throw error;
    }
  }

  async GetWriteCharacteristics(): Promise<void> {
    try {
      console.log('Getting write characteristics...');
      this._writeCharacteristic = await this.communicationService?.getCharacteristic(CharacteristicUUID.write);
    } catch (error) {
      console.log(error);
      this.disconnectDeviceAndServices();
      throw error;
    }
  }

  async WriteCommandsToCharacteristic() {
    try {
      console.log('Executing command sequence...');
      for (let i = 0; i < commandSequenceInitiate.length; i++) {
        this.currentCommand = commandSequenceInitiate[i];
        if (!this.isDeviceActive) {
            this._hasCommandsExecuted = false;
            break;
        }
        await this.writeToCharacteristic(this.writeCharacteristic, this.currentCommand, 5);
        // Wait before writing next command
         await delay(100);
      }
      this._hasCommandsExecuted = true;
    } catch (error) {
      console.log(error);
      this.disconnectDeviceAndServices();
      throw error;
    }
  }

  /**
   * Function to write data to writable characteristic.
   * @param writeCharacteristic Characteristic to write to.
   * @param command Command to write in HEX-form.
   * @param retries Number of retries on failure.
   * @param retry Current retry, usually not provided by first caller.
   */
  async writeToCharacteristic(writeCharacteristic: BluetoothRemoteGATTCharacteristic | undefined, command: string, retries: number, retry = 0): Promise<void> {
    try {
      await writeCharacteristic?.writeValue(hexStringToByte(command));
      await delay(100);
    } catch (error) {
        console.log(`writeCharacteristic error caught, will retry. Error = ${error}`);
        if (!this.isDeviceActive) {
            console.log('Device is disconnected, ignoring error and and stopping retries');
            return;
        } else if (retry < retries){
            await delay(400);
            await this.writeToCharacteristic(writeCharacteristic, this.currentCommand, retries, retry + 1);
        }else {
            this.disconnectDeviceAndServices();
            throw error;
        }
    }
  }

  async inventoryWriteToCharacteristics() {
    for (let i = 0; i < commandSequenceInventory.length; i++) {
        if(!this._isDeviceActive) {
            break;
        }
        this.currentCommand = commandSequenceInventory[i];
        await this.writeToCharacteristic(this.writeCharacteristic, this.currentCommand, 5);
        await delay(500);
    }
  }
  
  disconnectGattService(): void {
    try {
      if (this.device?.gatt?.connected) {
        this.device.gatt.disconnect();
      }
      this.communicationService = undefined;
      this._hasCommandsExecuted = false;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  disconnectServer(): void {
    try {
      if (this.server) {
        this.server = undefined;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  removeDevice(): void {
    try {
      if (this.device) {
        this.device = undefined;
        this._isDeviceActive = false;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  disconnectDeviceAndServices(): void {
    try {
      this.disconnectServices();
      console.log('Removing BT device...');
      this.removeDevice();
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  disconnectServices(): void {
    try {
      console.log('Disconnecting services...');
      this.disconnectServer();
      this.disconnectGattService();
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}