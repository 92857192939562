import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthComponent } from './auth/login/auth.component';
import { FailedComponent } from './auth/failed/failed.component';
import { HomeComponent } from './home/home.component';
import { QrReaderComponent } from './shared/components/qr-reader/qr-reader.component';
import { RfidComponent } from './rfid/rfid.component';
import { ItemsComponent } from './items/items.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'rfid',
    component: RfidComponent,
    canActivate: [MsalGuard]
  },
  { 
    path: 'items',
    component: ItemsComponent, 
    canActivate: [MsalGuard]
  },
  {
    path: 'qr',
    component: QrReaderComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
