<app-header></app-header>
<router-outlet></router-outlet>

<!-- Show QR-code reader, RFID and NFC icons if logged in and supported -->
<ng-container *ngIf="authService.loggedIn$ | async">

  <!-- <button
    *ngIf="nfcAvailable"
    class="fab-button nfc"
    mat-fab
    color=""
    aria-label="Button with nfc icon"
    (click)="nfcScan()"
  >
    <mat-icon>nfc</mat-icon>
  </button> -->

  <button
  *ngIf="rfidAvailable"
  class="fab-button rfid"
  mat-fab
  color=""
  routerLink="/rfid"
  aria-label="Button with rfid icon"
>
  <mat-icon svgIcon="rfid"></mat-icon>
</button>

  <button
    class="fab-button qr"
    mat-fab
    color=""
    aria-label="Button with qr scanner icon"
    routerLink="/qr"
    (click)="cancelScan()"
  >
    <mat-icon>qr_code_scanner</mat-icon>
  </button>
</ng-container>

<!-- Show scanning icon while scanning for NFC -->
<div class="scanning" *ngIf="isScanning">
  <p>Scanning for NFC tag...</p>
  <button mat-fab color="" aria-label="Scanning NFC spinner">
    <mat-icon class="rotate">radar</mat-icon>
  </button>
  <button
    mat-mini-fab
    color="accent"
    (click)="cancelScan()"
    aria-label="Button with close icon"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<!-- New version install prompt -->
<div class="update-bar" *ngIf="!modalPwaPlatform && modalVersion">
  <p>A new version of this app is available</p>
  <span>
    <button mat-raised-button color="primary" (click)="updateVersion()">
      <mat-icon>system_update</mat-icon>
      Update
    </button>
    <button mat-raised-button color="warn" (click)="closeVersion()">
      <mat-icon>cancel</mat-icon>
      Cancel
    </button>
  </span>
</div>

<!-- Custom install prompt -->
<div class="install-bar" *ngIf="modalPwaPlatform === 'ANDROID'">
  <p>Add Martela InstallBase app to home screen</p>
  <span>
    <button mat-raised-button color="primary" (click)="addToHomeScreen()">
      <mat-icon>install_mobile</mat-icon>
      Add
    </button>
    <button mat-raised-button color="warn" (click)="closePwa()">
      <mat-icon>cancel</mat-icon>
      Cancel
    </button>
  </span>
</div>

<!-- Online/offline -->
<div class="status" [style.backgroundColor]="isOnline ? 'green' : 'red'">
  {{ isOnline ? 'Online' : 'Offline' }}
</div>
