<ng-container *ngIf="addresses$ | async as addresses; else noResults">
  <mat-form-field appearance="fill">
    <mat-label>Search for address here</mat-label>
    <input matInput type="text" [(ngModel)]="searchValue" (input)="search(searchValue)">
    <button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''; search(searchValue)">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-card *ngFor="let address of addresses">
    <p>{{ address.street }}</p>
    <p>{{ address.zipCode }} {{ address.city }}</p>
    <button mat-button class="add-item green" (click)="emitAddressId(address.id)">
      <mat-icon>add_circle</mat-icon>
      Choose address
    </button>
  </mat-card>
</ng-container>
<ng-template #noResults>
  <mat-card> No addresses found </mat-card>
</ng-template>
