import { ErrorHandler, Injectable } from '@angular/core';
import { SnackbarService } from '../../shared/services/snack-bar.service';
import { Router } from '@angular/router';
import { AppInsightsService } from './app-insights.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private snackBarService: SnackbarService,
    private router: Router,
    private appInsightsService: AppInsightsService
  ) {}

  incompatibleDevice: string =
    'Selected bluetooth device not compatible, please select another device';
  gattServerFailure: string =
    'There was an issue with the bluetooth server, please try again';

  deviceSelectionCanceled: string = 'Device selection cancelled';

  handleError(error: Error) {
    console.log(`Error stack: ${error.stack}`);
    console.log(`Error message: ${error.message}`);

    if (environment.production) {
      this.appInsightsService.logException(error); // Manually log exception
    }

    // https://github.com/zxing-js/ngx-scanner/issues/297
    if (
      error.message.includes('setPhotoOptions failed') ||
      error.message.includes('setOptions failed')
    ) {
      console.log(
        'SetOptions failed related to qr scanning, should be handled/implemented better. Ignoring error'
      );
      return;
    }

    if (error.message.includes('No Services matching UUID')) {
      error.message = this.incompatibleDevice;
    }

    if (error.message.includes('GATT Server is disconnected')) {
      error.message = this.gattServerFailure;
    }

    if (error.message.includes('User cancelled the requestDevice()')) {
      console.log(
        'User cancelled bluetooth device selection, should be handled/implemented better. Ignoring error'
      );
      this.snackBarService.open(this.deviceSelectionCanceled, 'Close');
    } else {
      this.snackBarService.open(error.message.slice(0, 150), 'Close');
    }

    this.router.navigate(['home']);
  }
}
