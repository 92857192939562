import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { msalConfig, loginRequest, protectedResources } from './auth-config';

import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FailedComponent } from './auth/failed/failed.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { AuthComponent } from './auth/login/auth.component';
import { QrReaderComponent } from './shared/components/qr-reader/qr-reader.component';
import { ItemComponent } from './items/item/item.component';
import { AddressesComponent } from './addresses/addresses.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ItemsComponent } from './items/items.component';
import { LogWindowComponent } from './log-window/log-window.component';
import { RfidComponent } from './rfid/rfid.component';
import { ProgressSpinnerComponent } from './shared/components/progress-spinner/progress-spinner.component';
import { SnackbarService } from './shared/services/snack-bar.service';
import { GlobalErrorHandler } from './shared/services/error.service';
import { BlueToothService } from './shared/services/bluetooth.service';
import { DialogComponent } from './shared/components/dialog/dialog.component';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(protectedResources.api.endpoint, protectedResources.api.scopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
    loginFailedRoute: '/login-failed',
  };
}
@NgModule({
    declarations: [
        AppComponent,
        QrReaderComponent,
        FailedComponent,
        HomeComponent,
        HeaderComponent,
        AuthComponent,
        ItemComponent,
        AddressesComponent,
        ItemsComponent,
        LogWindowComponent,
        RfidComponent,
        ProgressSpinnerComponent,
        DialogComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        ZXingScannerModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatDialogModule,
        MsalModule.forRoot(new PublicClientApplication(msalConfig), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: protectedResources.api.scopes
            }
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([
                [protectedResources.api.endpoint, protectedResources.api.scopes]
            ])
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        SnackbarService,
        BlueToothService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
