import { AfterViewInit, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged, map, of } from 'rxjs';
import { Address, Item } from '../shared/interfaces/interfaces';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit, AfterViewInit {

  @Input() item!: Item;
  @Output() addressEvent = new EventEmitter<string>();

  addresses$!: Observable<Address[]>;
  searchValue = '';
  searchTerm = new Subject<string>();

  constructor() { }

  ngOnInit(): void {
    // Return addresses search results. Don't show current address.
    this.addresses$ = this.searchTerm.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map((term: string) => {
        return this.item.addresses.filter((address) => {
          return this.item.addressId !== address.id && Object.values(address).join('').includes(term);
        })
      })
    );
  }

  ngAfterViewInit(): void {
    this.search('');
  }

  search(term: string): void {
    this.searchTerm.next(term);
  }

  emitAddressId(addressId: string): void {
    this.addressEvent.emit(addressId);
  }

}
