import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { buildIdArray } from 'src/app/utils/utils';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { take } from 'rxjs';

@Component({
  selector: 'app-qr-reader',
  templateUrl: './qr-reader.component.html',
  styleUrls: ['./qr-reader.component.scss']
})
export class QrReaderComponent implements OnInit, OnDestroy {
  scannerEnabled!: boolean;
  scannedCode!: string;
  loading = false
  currentDevice!: MediaDeviceInfo;
  @ViewChild('scanner') scanner!: ZXingScannerComponent;
  constructor(private router: Router, public dataService: DataService) {}

  ngOnInit(): void {
    this.scannedCode = ''
    this.scannerEnabled = true;
  }

  ngOnDestroy(): void {
    this.scannerEnabled = false;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    if (devices.length > 0) {
      this.currentDevice = devices[0]
    }
  }

  scanSuccessHandler(event: string): void {
    // Prevent request loop of same code scann
    if (event && (this.scannedCode !== event)) {
        this.scannedCode = event
        this.scannerEnabled = false;
        this.scanner.reset()
        this.scanner.scanStop()
        this.dataService.tempGetItems(buildIdArray(event));
      }
  }

  scanErrorHandler(event: any): void  {
    if (event.message) {
      if(event.message.includes('setOptions failed')) {
        console.log('SetOptions issue, ignoring error for the time being');
        return;
      }
    }
 }

 close(): void {
    if (this.scanner) {
      this.scanner.reset()
      this.scanner.scanStop()
    }
    this.scannerEnabled = false;

  }

  goHome(): void {
    if (this.scanner) {
      this.scanner.reset()
      this.scanner.scanStop()
    }
    this.scannerEnabled = false;
    this.router.navigate(['home']);
  }
}
