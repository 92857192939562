import { Component, Input } from '@angular/core';
import { Item } from '../../shared/interfaces/interfaces';
import { DataService } from '../../shared/services/data.service';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent {

  @Input() item!: Item;
  showAddressList = false;

  constructor(private dataService: DataService) { }

  changeAddress(addressId: string, item: Item): void {
    this.dataService.changeItemAddress(item, addressId);
    this.showAddressList = false;
  }

  removeItem(uniqueId: string): void {
    this.dataService.removeItem(uniqueId);
    this.showAddressList = false;
  }

}
