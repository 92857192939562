export const environment = {
  production: true,
  clientId: '314db7b5-ce82-41ff-b450-010c40d3a8c9',
  authority: 'https://martelab2c.b2clogin.com/martelab2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
  redirectUri: 'https://storinstallbaseprodweu.z6.web.core.windows.net/',
  postLogoutRedirectUri: '/',
  apiUri: 'https://apim-installbase-prod-westeu-001.azure-api.net/MobileApp/v1',
  apiScopes: ['https://martelab2c.onmicrosoft.com/314db7b5-ce82-41ff-b450-010c40d3a8c9/MobileApp.All'],
  authorityDomain:'https://martelab2c.b2clogin.com',
  graphUri: 'https://graph.microsoft.com/v1.0/me',
  graphScopes: ['user.read'],
  instrumentationKey: "01ba453c-52d0-4050-ba42-c5a5660363d0"
};
