<div *ngIf="itemsFetched; else loader">
  <ng-container *ngIf="(items$ | async)?.length; else noResults">
    <div class="content" *ngIf="(items$ | async)?.length! > 1">
      <p (click)="removeItems()">
        clear all {{(items$ | async)?.length }} results <mat-icon class="button-remove">cancel</mat-icon>
      </p>
    </div>
    <app-item *ngFor="let item of items$ | async" [item]="item"></app-item>
  </ng-container>
  <ng-template #noResults>
    <mat-card> No items found </mat-card>
  </ng-template>
</div>
<ng-template #loader>
  <div class="loader-container">
    <app-progress-spinner progressMessage="Fetching items from database, please wait..."></app-progress-spinner>
  </div>
</ng-template>