<div>
  <img src="../../assets/logo.svg" alt="Martela logo" />
  <span *ngIf="authService.loggedIn$ | async">
    <button mat-raised-button routerLink="/home">
      <mat-icon>home</mat-icon>
      Home
    </button>
    <button mat-raised-button (click)="logout()">
      <mat-icon>logout</mat-icon>
      Logout
    </button>
  </span>
</div>
