import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Item } from '../shared/interfaces/interfaces';
import { DataService } from '../shared/services/data.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  
  items$!: Observable<Item[]>;
  itemsFetched: boolean = false;

  constructor(
    private dataService: DataService,
    private router: Router) { }

  ngOnInit(): void {
    this.items$ = this.dataService.items$.pipe(
      map((items) => {
        return items.map((item) => {
          if (item?.addressId) {
            item.address = item.addresses.find((address) => address.id === item.addressId);
          }
          return item;
        })
      }),
    );

    this.dataService.items$.subscribe(items => {
      if(items.length > 0) {
        this.itemsFetched = true;
      }
    })
  }
  
  removeItems(): void {
    this.dataService.removeItems();
    this.router.navigate(['home']);
  }

}
