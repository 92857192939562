import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, forkJoin, Observable, of, ReplaySubject, take } from 'rxjs';
import { Item } from '../interfaces/interfaces';
import { environment } from 'src/environments/environment';
import { SnackbarService } from './snack-bar.service';
import { Router } from '@angular/router';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private apiUrl = environment.apiUri;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  private _items$ = new BehaviorSubject<Item[]>([]);
  public get items$(): Observable<Item[]> {
    return this._items$.asObservable();
  }

  private $loading = new ReplaySubject<boolean>(1);
  public loading$ = this.$loading.asObservable()

  constructor(
    private http: HttpClient, 
    private snackbarService: SnackbarService,
    private router: Router,
    private appInsightsService: AppInsightsService
  ) { }

  getItems(uniqueIds: string[]): void {
    const itemObservables: Observable<Item>[] = [];
    uniqueIds.map((uniqueId) => {
      if (this._items$.value.findIndex((item) => item?.uniqueId === uniqueId) === -1) {
        itemObservables.push(this.getItem(uniqueId));
      }
    })
    forkJoin([...itemObservables]).subscribe((items) => {
      this._items$.next([...this._items$.value, ...items])
    });
  }
  
  tempGetItems(uniqueIds: string[]): void {
    this.$loading.next(true)
    const itemObservables: Observable<Item>[] = [];

    uniqueIds.map((uniqueId) => {
      if (this._items$.value.findIndex((item) => item?.uniqueId === uniqueId) === -1) {
        itemObservables.push(this.getTempItem(uniqueId));
      }
    })
    forkJoin([...itemObservables]).subscribe({
      next: (items) => {
        this._items$.next([...this._items$.value, ...items])
        this.$loading.next(false)
        this.router.navigate(['items']);
      },
      error: (e) => {
        this.$loading.next(false)
        if (environment.production) {
          this.appInsightsService.logException(e); // Manually log exception to appInsights
        }
        if (!e.message.includes("setOptions failed") || !e.trace.includes("setOptions failed")) {
          this.snackbarService.open('Failed to get information on QR-code: ' + e.message, 'Close')
          this.router.navigate(['home']);
        }
        this.snackbarService.open('ERR: ' + e.message, 'Close')
        this.router.navigate(['home']);
      }
    });
  }

  getItem(uniqueId: string): Observable<Item> {
    return this.http.get<Item>(`${this.apiUrl}/item/${uniqueId}`)
      .pipe(
        catchError(this.handleError<Item>('getItem', { 'uniqueId': uniqueId } as Item))
      )
  }

  getTempItem(uniqueId: string): Observable<Item> {
    return this.http.get<Item>(`${this.apiUrl}/item/${uniqueId}`)
  }

  removeItem(uniqueId: string): void {
    this._items$.next(this._items$.value.filter((item) => item.uniqueId !== uniqueId));

  }

  removeItems(): void {
    const emptyItems = new BehaviorSubject<Item[]>([]);
    this._items$.next(emptyItems.value);
  }

  changeItemAddress(item: Item, newAddressId: string): void {
    const items = this._items$.value;
    const index = items.findIndex((cur) => cur.uniqueId === item.uniqueId);
    const requestBody = { 
      accountId: item.account.id,
      addressId: newAddressId,
    }
    this.http.put<Item>(`${this.apiUrl}/item/${item.uniqueId}/address`, JSON.stringify(requestBody), this.httpOptions)
      .pipe(
        catchError(this.handleError<Item>('changeItemAddress', undefined))
      )
      .subscribe((result) => {
        items[index] = result;
        this._items$.next(items);
      });
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 *
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
