import { EventCodes, StatusCodes } from "../shared/enums/rfid-enums";
import { hexStringToASCIIString } from 'src/app/utils/utils';
import { Response } from '../shared/interfaces/interfaces';

// CS108 rfid reader send notifications with A7 in the start of it's headers
export const isNotification = (response: string): boolean => 
    response.startsWith('A7') ? true : false;

export const isStatusNotification = (payload: string, response: string): boolean => 
    !!payload.slice(4) && isNotification(response) ? true : false;

export const isBarCodeData = (response: string): boolean =>
    response.endsWith('0501111603040D') ? true : false;

// Raw tag id data picked up with BT seems to lack the header added by CS108
export const isTagIdData = (response: string): boolean => 
      !response.startsWith('A7') ? true : false;

// Try to determine if tag data is of Martela type based on RegEx rules
export const isExpectedTagData = (response: string): boolean => {
    if (!isTagIdData(response)) {
        return false;
    }
    const isMatched = response.startsWith(rfidTagPrefix);
    isMatched ? '' : console.log('Filtered out tag data picked up: ', response);
    return isMatched ? true : false;
  };

// event code 'Rfid firmware response data' and pkt_ver 'compact mode inventory packet'
export const isNextMsgRfid = (response: string): boolean =>
  eventCodes[response.slice(16, 22)] === EventCodes.CompactModePacketVersion ? true : false;

export const isTriggerPull = (response: string): boolean => 
    eventCodes[response.slice(0, 4)] === EventCodes.TriggerPulled ? true : false;

// Rfid tag TID prefix
export const rfidTagPrefix: string = 'E280';

// Event codes sent from RFID reader
export const eventCodes: Record<string, EventCodes> = {
    'A101': EventCodes.Error,
    'A102': EventCodes.TriggerPulled,
    'A103': EventCodes.TriggerReleased,
    '8000': EventCodes.RfidPowerOn,
    '8001': EventCodes.RfidPowerOff,
    '8002': EventCodes.FirmwareCommandData,
    '8100': EventCodes.FirmwareResponseData,
    '9000': EventCodes.BarcodePowerOn,
    '9001': EventCodes.BarcodePowerOff,
    '9002': EventCodes.BarcodeStartScan,
    '9100': EventCodes.BarcodeReadData,
    '9101': EventCodes.BarcodeGoodRead,
    '810004': EventCodes.CompactModePacketVersion
 };

 // Status codes sent from RFID reader
 export const statusCodes: Record<string, StatusCodes> = {
    '00': StatusCodes.Success,
    'FF': StatusCodes.UnknownFailure,
    '0000': StatusCodes.WrongHeaderPrefix,
    '0001': StatusCodes.PayloadTooLarge,
    '0002': StatusCodes.UnknownTarget,
    '0003': StatusCodes.UnknownEvent,
    '4003BFFCBFFCBFFC': StatusCodes.CommandAbortResponse
 };

  // Status codes sent from RFID reader as friendly text
 export const statusCodeText: Record<StatusCodes, string> = {
    [StatusCodes.Success]: 'Success',
    [StatusCodes.UnknownFailure]: 'Unknown failure',
    [StatusCodes.WrongHeaderPrefix]: 'Wrong header prefix',
    [StatusCodes.PayloadTooLarge]: 'Payload length too large',
    [StatusCodes.UnknownTarget]: 'Unknown target',
    [StatusCodes.UnknownEvent]: 'Unknown event',
    [StatusCodes.CommandAbortResponse]: 'Command response of abort operation'
 };

 // Event codes sent from RFID reader as friendly text
export const eventCodeText: Record<EventCodes, string> = {
    [EventCodes.Error]: 'Error code',
    [EventCodes.TriggerPulled]: 'Trigger pull',
    [EventCodes.TriggerReleased]: 'Trigger release',
    [EventCodes.RfidPowerOn]: 'Rfid power on',
    [EventCodes.RfidPowerOff]: 'Rfid power off',
    [EventCodes.FirmwareCommandData]: 'Rfid firmware command data',
    [EventCodes.FirmwareResponseData]: 'Rfid firmware response data',
    [EventCodes.BarcodePowerOn]: 'Barcode power on',
    [EventCodes.BarcodePowerOff]: 'Barcode power off',
    [EventCodes.BarcodeStartScan]: 'Barcode start scan',
    [EventCodes.BarcodeReadData]: 'Barcode read/reply data',
    [EventCodes.BarcodeGoodRead]: 'Barcode good read',
    [EventCodes.CompactModePacketVersion]: 'Rfid firmware response data and pkt_ver compact mode inventory packet'
};

export function getResponseCodeText(response: string) {
    const code = eventCodes[response] ?? 'Unknown event code';
    return eventCodeText[code] ?? 'Unknown event code';
};

export function getResponseStatusText(response: string) {
    const code = statusCodes[response] ?? 'Unknown status code';
    return statusCodeText[code] ?? 'Unknown status code';
};

/**
* Interpret the response from notify characteristic
* @param resp Response received in notify characteristic callback.
* @returns Response (full msg, header, payload and description)
*/
export function interpretResponse(response: string): Response {

    const payload = isNotification(response) ? response.slice(16) : response;

    let header = undefined;
    let status = '';
    let eventCode = '';
    let data = '';

    if (isNotification(response)) {
        header = response.slice(0, 16);
        eventCode = getResponseCodeText(payload.slice(0, 4));
    }
    
    // Status
    status = isStatusNotification(payload, response) ? getResponseStatusText(payload.slice(0, 4)) : ''; 

    // Barcode
    if (isBarCodeData(response)) {
        data = `barcode ${hexStringToASCIIString(response.slice(0, -14))}`;
    }

    return {
        full: response,
        header,
        payload,
        description: isNotification(response) ? `${eventCode} ${status}` : `Data: ${data}`,
    };
}