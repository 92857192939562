<ng-container *ngIf="item?.itemId; else notFound">
  <mat-card
    [class]="item.addressSetByMobileApp ? 'confirmed' : 'not-confirmed'"
  >
    <div class="header">
      <h4>{{ item.itemName }}</h4>
      <mat-icon (click)="removeItem(item.uniqueId)">cancel</mat-icon>
    </div>
    <p>Item id: {{ item.uniqueId }}</p>
    <p>Product id: {{ item.itemId }}</p>
    <ng-container *ngIf="item.address; else noAddress">
      <p>{{ item.address.street }}</p>
      <p>{{ item.address.zipCode }} {{ item.address.city }}</p>
      <!-- Buttons -->
      <div class="buttons">
        <!-- Confirm -->
        <button
          [disabled]="item.addressSetByMobileApp"
          mat-raised-button
          class="green"
          (click)="changeAddress(item.addressId, item)"
        >
          <mat-icon>check_circle</mat-icon>
          {{
            item.addressSetByMobileApp ? "Address confirmed" : "Confirm address"
          }}
        </button>
        <!-- Change address toggle -->
        <button
          *ngIf="!showAddressList"
          mat-raised-button
          (click)="showAddressList = true"
        >
          <mat-icon>change_circle</mat-icon>
          Change address
        </button>
        <button
          *ngIf="showAddressList"
          mat-raised-button
          (click)="showAddressList = false"
        >
          <mat-icon>expand_less</mat-icon>
          Hide address list
        </button>
      </div>
      <!-- Address list -->
      <app-addresses
        *ngIf="showAddressList"
        (addressEvent)="changeAddress($event, item)"
        [item]="item"
      ></app-addresses>
    </ng-container>
    <ng-template #noAddress>
      <p>This item has no default address.</p>
    </ng-template>
  </mat-card>
</ng-container>
<ng-template #notFound>
  <mat-card>
    <div class="header">
      <p>Item with uniqueId {{ item.uniqueId }} not found. Please try again.</p>
      <mat-icon (click)="removeItem(item.uniqueId)">cancel</mat-icon>
    </div>
  </mat-card>
</ng-template>
