import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
 
export const b2cPolicies = {
     names: {
         signUpSignIn: "b2c_1_susi_reset_v2",
         editProfile: "b2c_1_edit_profile_v2"
     },
     authorities: {
         signUpSignIn: {
             authority: environment.authority,
         },
         editProfile: {
             authority: environment.authority
         }
     },
     authorityDomain: environment.authorityDomain
 };
 
 
export const msalConfig: Configuration = {
     auth: {
         clientId: environment.clientId,
         authority: environment.authority,
         knownAuthorities: [environment.authorityDomain],
         redirectUri: environment.redirectUri,
         postLogoutRedirectUri: environment.postLogoutRedirectUri, 
     },
     cache: {
         cacheLocation: BrowserCacheLocation.LocalStorage,
         storeAuthStateInCookie: isIE, 
     },
     system: {
      loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
          console.log(message);
      },
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
 }

export const protectedResources = {
  api: {
    endpoint: environment.apiUri,
    scopes: environment.apiScopes,
  }
}

export const loginRequest = {
  scopes: []
};
