import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  items: Map<string, number> = new Map<string, number>();

  addToTags(tag: string) {
    // Check if rfid tag is already read and if so, increment count.
    this.items.set(tag, (this.items.get(tag) ?? 0) + 1);
  }

  getTags() {
    return this.items;
  }

  clearTags() {
    this.items.clear();
  }
}