export enum EventCodes {
    Error ='A101',
    TriggerPulled ='A102',
    TriggerReleased ='A103',
    RfidPowerOn = '8000',
    RfidPowerOff = '8001',
    FirmwareCommandData = '8002',
    FirmwareResponseData = '8100',
    BarcodePowerOn = '9000',
    BarcodePowerOff = '9001',
    BarcodeStartScan = '9002',
    BarcodeReadData = '9100',
    BarcodeGoodRead = '9101',
    CompactModePacketVersion = '810004'
}

export enum StatusCodes {
    Success = '00',
    UnknownFailure = 'FF',
    WrongHeaderPrefix = '0000',
    PayloadTooLarge = '0001',
    UnknownTarget = '0002',
    UnknownEvent = '0003',
    CommandAbortResponse = '4003BFFCBFFCBFFC'      
}