import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackbarService {
    constructor(
      private snackBar: MatSnackBar,
      private zone: NgZone
    ) { }

    public open(message: string, action = 'success'): void {
        this.zone.run(() => {
          this.snackBar.open(message, action, { 
            duration: 6000, 
            panelClass: 'snackbar-default' });
        });
    }
}