export enum progressMessages {
    Init = "Initializing",
    ServerConnect = "Connecting to BL device server",
    GetCommunicationService = "Connecting to BL service",
    GetNotificationCharacteristic = "Fetching BL notification characteristics",
    StartNotificationService = "Initializing BL notification service",
    GetWriteCharacteristic = "Fetching BL write characteristics",
    ExecutingCommands = "Executing reader commands, please wait",
    ReadyToScan = "Press device trigger to start scanning",
    ScanningInit = "Scanning initiated",
}