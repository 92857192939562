<!-- Show RFID icon if logged in and supported -->
<ng-container *ngIf="authService.loggedIn$ | async">
  <div>
    <div class="header-container">
      <div class="column">
        <h2>Rfid</h2>
      </div>
      <div class="column align-right">
        <button
        class="bt-button"
        mat-mini-fab
        color="primary"
        [disabled]="!this.device"
        (click)="disconnectBluetoothWithDialog()"
        aria-label="Button with cloud download icon">
        <mat-icon>bluetooth</mat-icon>
      </button>
      </div>
    </div>
    <ng-container *ngIf="initiatingRfid; else initialized">
      <p>Initializing, please wait...</p>
      <app-progress-spinner progressMessage= {{this.progressMessage}}></app-progress-spinner>
    </ng-container>

    <ng-template #initialized>
      <p *ngIf="!rfidTags.size">Rfid reader initialized. {{this.progressMessage}} </p>
      <p *ngIf="isTriggerActionActive"><strong>Scanning in progress...</strong></p>

      <div *ngIf="rfidTags.size">
       <p><strong>{{ rfidTags.size }} tags found </strong> in the vicinity</p>
       <p>Press left button below to fetch items from database</p>
       <p *ngIf="!isTriggerActionActive">Pull reader trigger to re-scan for tags</p>
      </div>

      <div class="action-button-container" *ngIf="rfidTags.size">     
          <button
          class="action-button"
          mat-mini-fab
          color="primary"
          (click)="getItems()"
          aria-label="Button with arrow circle down icon"
          >
          <mat-icon>arrow_circle_down</mat-icon>
        </button>
        <button
        class="action-button"
        mat-mini-fab
        color="primary"
        (click)="ClearTagsAndItems()"
        aria-label="Button with arrow circle down icon"
        >
        <mat-icon>clear</mat-icon>
      </button>
      </div>
    </ng-template>

    <!-- Debug data -->
    <div *ngIf="debug" class="log">
      <hr>
      <p>Tags fetched</p>
      <p *ngIf="rfidTags.size">
          {{ rfidTags.size }} tags
      </p>
      <p *ngFor="let tag of rfidTags | keyvalue">
        Rfid tag: {{ tag.key }} (scanned {{ tag.value }} times)
      </p>
      <div>
        <p>Logged notification responses</p>
        <p *ngFor="let line of logWindow">
          {{ line }}
        </p>
      </div>
    </div>
  </div>

</ng-container>
