import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  data$!: Observable<any>;
  constructor(public authService: AuthService) { }

  ngOnInit(): void {
    this.data$ = this.authService.dataSource$;
  }
}
