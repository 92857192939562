import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, filter, Observable, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly _destroying$ = new Subject<void>();

  private _loggedIn$ = new BehaviorSubject<boolean>(false);
  public get loggedIn$(): Observable<boolean> {
    return this._loggedIn$.asObservable();
  }
  private _dataSource = new BehaviorSubject<any>([]);
  public get dataSource$(): Observable<any> {
    return this._dataSource.asObservable();
  }

  constructor(private msalBroadcastService: MsalBroadcastService, private msalAuthService: MsalService ) {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.msalAuthService.instance.setActiveAccount(payload.account);
      });

      this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoggedIn();
        this.checkAndSetActiveAccount();
        this.getClaims(this.msalAuthService.instance.getActiveAccount()?.idTokenClaims)
      });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalAuthService.instance.getActiveAccount();

    if (!activeAccount && this.msalAuthService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalAuthService.instance.getAllAccounts();
      this.msalAuthService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoggedIn() {
    this._loggedIn$.next(this.msalAuthService.instance.getAllAccounts().length > 0);
  }

  getClaims(claims: any) {
    this._dataSource.next([
      {id: 1, claim: "Display Name", value: claims ? claims['name'] : null},
      {id: 2, claim: "User Principal Name (UPN)", value: claims ? claims['preferred_username'] : null},
      {id: 3, claim: "OID", value: claims ? claims['oid']: null}
    ]);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
