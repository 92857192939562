/// <reference types="web-bluetooth" />

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from '../shared/services/data.service';
import { TagService } from '../shared/services/tag.service';
import { BlueToothService } from '../shared/services/bluetooth.service';
import { DialogComponent } from '../shared/components/dialog/dialog.component';
import { byteToHexString } from 'src/app/utils/utils';
import { progressMessages } from '../shared/enums/progress-enums';
import { isExpectedTagData, isNextMsgRfid, isTriggerPull, interpretResponse } from '../utils/response';
import { Response } from '../shared/interfaces/interfaces';

@Component({
  selector: 'app-rfid',
  templateUrl: './rfid.component.html',
  styleUrls: ['./rfid.component.scss'],
})

export class RfidComponent implements OnInit {
  notificationEventListenerCallBack: any | undefined;
  rfidAvailable: boolean = false;
  initiatingRfid: boolean = false;
  nextMsgIsRfid: boolean = false;
  isTriggerActionActive: boolean = false;
  debug: boolean = false;
  logWindow: Array<string> = [];
  progressMessage: string = '';
  rfidTags: Map<string, number> = new Map<string, number>();

  notifyCharacteristic: BluetoothRemoteGATTCharacteristic | undefined;
  device: BluetoothDevice | undefined;

  constructor(
    public authService: AuthService,
    private dataService: DataService,
    private tagService: TagService,
    private blueToothService: BlueToothService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  async ngOnInit(): Promise<void> {
    console.log('Rfid component init');
    this.rfidTags = this.tagService.getTags();
    console.log('what is TAGS onInit= ', this.rfidTags)
    this.rfidAvailable = navigator.bluetooth ? true : false;
    this.notificationEventListenerCallBack = this.handleNotification.bind(this);
    await this.rfidScan();
  }

  /**
   * Function triggered by pressing RFID icon. Handles scanning of bar codes and rfid tags.
   */
  rfidScan = async () => {
    try {
      this.setProgressMessage(progressMessages.Init);
      this.initiatingRfid = true;
      // Get bluetooth device
      this.device = await this.blueToothService.getDevice();
      // Setup and connect to GATT server
      this.setProgressMessage(progressMessages.ServerConnect);
      await this.blueToothService.setupAndConnectToGATTServer();
      // Get GATT communication service
      this.setProgressMessage(progressMessages.GetCommunicationService);
      await this.blueToothService.getCommunicationService(5);
      // Get notification characteristic
      this.setProgressMessage(progressMessages.GetNotificationCharacteristic);
      this.notifyCharacteristic = await this.blueToothService.getNotificationCharacteristic();
      // Start notifications
      await this.notifyCharacteristic?.startNotifications();
      // Start listen for changes
      this.notifyCharacteristic?.addEventListener('characteristicvaluechanged', this.notificationEventListenerCallBack); // Keep scope
      // Get write characteristic
      await this.blueToothService.GetWriteCharacteristics();

      // Write commands to characteristic if not already done with current device
      if (!this.blueToothService.hasCommandsExecuted) {
        this.setProgressMessage(progressMessages.ExecutingCommands);
        await this.blueToothService.WriteCommandsToCharacteristic();
      }
 
      this.initiatingRfid = false;
      this.setProgressMessage(progressMessages.ReadyToScan);  

    } catch (error) {
        this.blueToothService.disconnectDeviceAndServices();
        throw error;
    }
  };

  /**
   * Callback function for listening to events emitted from characteristic.
   * @param event Event emitted from notification characteristic.
   */
  async handleNotification(event: any): Promise<void> {
    console.log('picked up notification');
    const value = event.target.value as DataView;
    const view = new Uint8Array(value.buffer);
    const strData = byteToHexString(view);

    if (this.nextMsgIsRfid && isExpectedTagData(strData)) {
      const rfidTag = strData.slice(0, 24);
      this.tagService.addToTags(rfidTag);

      this.nextMsgIsRfid = false;
    }

    this.nextMsgIsRfid = isNextMsgRfid(strData);
    const response = interpretResponse(strData);
    this.setupDebugData(response);

    if (isTriggerPull(response.payload) && !this.isTriggerActionActive) {
      console.log('TRIGGER PULL > SCANNING TID:s');
      this.isTriggerActionActive = true;
      this.setProgressMessage(progressMessages.ScanningInit);
      await this.blueToothService.inventoryWriteToCharacteristics();
      console.log('FINISHED SCAN');
      this.isTriggerActionActive = false;
    }
  }

  private setupDebugData(response: Response) {
    if (this.debug) {
      const strMsg = (response.header ? response.header + ' : ' : '') + response.payload + ' : ' + response.description;
      this.logWindow.push(strMsg);
    }
  }

 async ClearTagsAndItems() {
    this.tagService.clearTags();
    this.dataService.removeItems();
    this.router.navigate(['home']);
  }

  rfidIsActiveRoute(): boolean {
    return (this.router.url === '/rfid') ? true : false;
  }

  setProgressMessage(message: string) {
    this.progressMessage = message;
  }

  async getItems(): Promise<void> {
    if (this.tagService.getTags().size > 0) {
      this.dataService.getItems(this.getTagKeys(this.tagService.getTags()));
      this.router.navigate(['items']);
    }
  }

  disconnectBluetoothWithDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Disconnect current device",
        message: "Are you sure you want to disconnect bluetooth device?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.blueToothService.disconnectDeviceAndServices();
        this.router.navigate(['home']);
      }
    })
  }

  getTagKeys(rfidTags: Map<string, number>): any {
    let idArray = [];
    for (const [key, _] of rfidTags.entries()) {
      idArray.push(key);
    }
    return idArray;
  }

  async ngOnDestroy() {
    this.notifyCharacteristic?.removeEventListener('characteristicvaluechanged', this.notificationEventListenerCallBack);
    await this.notifyCharacteristic?.stopNotifications();
    console.log('notifications stopped and eventListener removed');
  }
}
