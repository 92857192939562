import { Platform } from '@angular/cdk/platform';
import { Component, NgZone, OnInit } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';
import { AuthService } from './shared/services/auth.service';
import { DataService } from './shared/services/data.service';
import { Router } from '@angular/router';
import { buildIdArray } from './utils/utils';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppInsightsService } from './shared/services/app-insights.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'Martela InstallBase';
  isOnline = false;
  modalVersion = false;
  nfcAvailable = false;
  rfidAvailable = false;
  isScanning = false;
  modalPwaEvent: any;
  modalPwaPlatform: string | undefined;

  constructor(
    public authService: AuthService,
    private platform: Platform,
    private swUpdate: SwUpdate,
    private dataService: DataService,
    private router: Router,
    private zone: NgZone,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appInsightsService: AppInsightsService
  ) {
    this.matIconRegistry.addSvgIcon(
      'rfid',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/rfid.svg'
      )
    );
  }

  ngOnInit(): void {
    this.router.navigate(['']);
    this.rfidAvailable = navigator.bluetooth ? true : false;
    this.nfcAvailable = 'NDEFReader' in window;
    this.updateOnlineStatus();
    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));

    // Handle new version.
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      ).subscribe(() => {
        this.modalVersion = true;
      })
    }

    // Customize install.
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.modalPwaEvent = event;
        this.modalPwaPlatform = 'ANDROID';
      });
    }

  }

  private updateOnlineStatus(): void {
    this.isOnline = window.navigator.onLine;
  }

  public updateVersion(): void {
    this.closeVersion();
    window.location.reload();
  }

  public closeVersion(): void {
    this.modalVersion = false;
  }

  /**
   * Function triggered på pressing NFC icon. Will handle scanning of NFC tags. TODO Move to separate component.
   */
  nfcScan = async () => {
    if ("NDEFReader" in window) {
      const ndef = new NDEFReader();
      try {
        this.isScanning = true;
        await ndef.scan();
        ndef.onreading = event => {
          if (this.isScanning) {
            const decoder = new TextDecoder();
            let ids = [] as string[];
            for (const record of event.message.records) {
              ids.push(decoder.decode(record.data));
            }
            // Reenter Angular zone - https://angular.io/api/core/NgZone#run
            this.zone.run(() => {
              this.cancelScan();
              this.dataService.getItems(buildIdArray(ids.join()));
              this.router.navigate(['items']);
            });
          }
        }
      } catch (error) {
        this.cancelScan();
        console.log(error);
        confirm('An error occured\nPlease try again');
      }
    } else {
      console.log('Web NFC is not supported.');
    }
  }

  cancelScan(): void {
    this.isScanning = false;
  }

  public addToHomeScreen(): void {
    this.modalPwaEvent.prompt();
    this.modalPwaPlatform = undefined;
  }

  public closePwa(): void {
    this.modalPwaPlatform = undefined;
  }

}
