export enum ServiceUUID {
  service1 = '0000180a-0000-1000-8000-00805f9b34fb',
  service2 = '00001801-0000-1000-8000-00805f9b34fb',
  communication = '00009800-0000-1000-8000-00805f9b34fb',
  service4 = '00001800-0000-1000-8000-00805f9b34fb'
}

export enum CharacteristicUUID {
  write = '00009900-0000-1000-8000-00805f9b34fb',
  notification = '00009901-0000-1000-8000-00805f9b34fb'
}

export enum BarcodeCommands {
  BARCODE_POWER_ON = 'A7 B3 02 6A 82 37 00 00 9000',
  BARCODE_START_SCAN = 'A7 B3 02 6A 82 37 00 00 9002'
}

export enum RfidCommands {
  RFID_POWER_ON = 'A7 B3 02 C2 82 37 00 00 8000',
}

export const commandSequenceInitiate: string[] = [
'A7B302C2823700008000',
'A7B30AC28237000080024003000000000000',
'A7B30AC28237000080027001000508000000',
'A7B30AC2823700008002700100F003000000',
'A7B30AC28237000080027001000509000000',
'A7B30AC2823700008002700100F003000000',
'A7B30AC2823700008002700100050A000000',
'A7B30AC2823700008002700100F003000000',
'A7B30AC2823700008002700100050B000000',
'A7B30AC2823700008002700100F003000000',
'A7B30AC28237000080027000000000000000',
'A7B30AC28237000080027001080C00010000',
'A7B30AC2823700008002700100F003000000',
'A7B30AC28237000080027001000502000000',
'A7B30AC2823700008002700100F003000000',
'A7B30AC2823700008002700100058F000000',
'A7B30AC2823700008002700100F003000000',
'A7B30AC2823700008002700100058E000000',
'A7B30AC2823700008002700100F003000000',
'A7B30AC28237000080027001000504000000',
'A7B30AC2823700008002700100F003000000',
'A7B30AC28237000080027001000505000000',
'A7B30AC2823700008002700100F003000000',
'A7B30AC28237000080027001000506000000',
'A7B30AC2823700008002700100F003000000',
'A7B30AC28237000080027001000507000000',
'A7B30AC2823700008002700100F003000000',
'A7B30AC28237000080027001000201000000',
'A7B30AC2823700008002700100F014000000',
'A7B30AC28237000080027000040200000000',
'A7B30AC28237000080027000020C00000000',
'A7B30AC28237000080027000060700000000',
'A7B30AC28237000080027000020C00000000',
'A7B30AC28237000080027000060700000000',
'A7B30AC28237000080027000020C00000000',
'A7B30AC282370000800270010007FFFF0000',
'A7B30AC28237000080027001050700000000',
'A7B30AC282370000800270010707FEFFFFFF',
'A7B30AC2823700008002700106072C010000',
'A7B30AC28237000080027001000450040000',
'A7B30AC282370000800270010F0F00000000',
'A7B30AC282370000800270010309F7405003',
'A7B30AC28237000080027001040900000000',
'A7B30AC28237000080027001050901000000',
'A7B30AC28237000080027001000980000000',
'A7B30AC28237000080027001030200000000',
'A7B30AC28237000080027001010800000000',
'A7B30AC28237000080027001020800000000',
'A7B30AC28237000080027001030800000000',
'A7B30AC28237000080027001040800000000',
'A7B30AC28237000080027001010903000000',
'A7B30AC28237000080027001050901000000',
'A7B30AC28237000080027001000900000000',
'A7B30AC28237000080027001010903000000',
'A7B30AC282370000800270010F0F00000000',
'A7B30AC28237000080027001010903000004',
];

export const commandSequenceInventory: string[] = [
'A7B30AC282370000800270010309F7405003',
'A7B30AC28237000080027001040900000000',
'A7B30AC28237000080027001050901000000',
'A7B30AC28237000080027001000980000000',
'A7B30AC28237000080027001030200000000',
'A7B30AC28237000080027001050901000000',
'A7B30AC28237000080027001000900000000',
'A7B30AC2823700008002700100F00F000000',
'A7B30AC28237000080024003000000000000',
'A7B30AC28237000080024003000000000000'
]