<div class="wrapper">
  <div class="viewFinder" *ngIf="scannerEnabled">
    <zxing-scanner
      #scanner
      (scanSuccess)="scanSuccessHandler($event)"
      (scanError)="scanErrorHandler($event)"
      (camerasFound)="onCamerasFound($event)"
      [(device)]="currentDevice"
    ></zxing-scanner>
    <div class="corner top left"></div>
    <div class="corner top right"></div>
    <div class="corner bottom left"></div>
    <div class="corner bottom right"></div>
  </div>
  <ng-container *ngIf="dataService.loading$ | async; else scanning">
    <p class="status">Loading data...</p>
  </ng-container>
  <ng-template #scanning>
    <p *ngIf="scannerEnabled" class="status">Loading...</p>
  </ng-template>
 
  <button
    mat-mini-fab
    color="accent"
    (click)="goHome()"
    aria-label="Button with close icon"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
